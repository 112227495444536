import React from "react";
import { Card } from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import GuideBuild from "@/feature-reskin-probuilds-net/components/GuideBuild.jsx";
import Layout from "@/feature-reskin-probuilds-net/components/Layout.jsx";
// import { ImgTag } from "@/game-lol/components/ChampionImg.jsx";
import MatchScoreboard from "@/game-lol/components/MatchScoreboard.jsx";
import PlayerScores from "@/game-lol/utils/player-scores.mjs";
// import Static from "@/game-lol/utils/static.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function Guide() {
  const {
    parameters: [region, matchId, championId],
  } = useRoute();
  const {
    lol: { matches, matchTimeline: timelines },
  } = useSnapshot(readState);

  const matchData = matches[`${region.toUpperCase()}_${matchId}`];
  const matchPlayer =
    (matchData?.participants || []).find(
      (p) => Number(p.championId) === Number(championId),
    ) || {};

  const timelineData = timelines[matchId];
  const timelinePlayer = (timelineData?.participants || []).find(
    (timelinePlayer) => timelinePlayer.puuid === matchPlayer.puuid,
  );

  return (
    <Layout
    // title={matchPlayer.riotIdGameName || "Loading..."}
    // image={Static.getChampionImage(championId)}
    // imageClassName={ImgTag()}
    >
      <Card padding="0">
        <MatchScoreboard
          region={region}
          matchId={matchId}
          localPlayer={{
            ...matchPlayer,
            riotAccount: { gameName: matchPlayer?.riotIdGameName },
          }}
          playerScores={PlayerScores(matchData)}
        />
      </Card>
      <GuideBuild
        matchPlayer={matchPlayer}
        timelinePlayer={timelinePlayer}
        timeline={timelineData}
      />
    </Layout>
  );
}

export default Guide;
